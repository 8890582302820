/* src/styles/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

@layer components {
  .header {
    @apply text-6xl font-bold text-blue-400 underline dark:bg-gray-800;
  }

  .smallheader {
    @apply text-2xl font-bold text-gray-800 dark:bg-gray-800 dark:text-white;
  }

  .smallestheader {
    @apply text-xl font-semibold mt-4 text-gray-800 dark:bg-gray-800 dark:text-white;
  }

  .button {
    @apply px-6 py-2 font-bold bg-blue-400 text-white rounded hover:bg-blue-800;
  }

  .submitbutton {
    @apply px-6 py-2 ml-2 font-bold bg-green-400 text-white rounded hover:bg-green-800;
  }

  .link {
    @apply hover:text-blue-700 hover:underline hover:cursor-pointer;
  }
}
